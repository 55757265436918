.mainpage {
    height: 100vh;

    @keyframes shake {
        0% { transform: translateX(0rem); }
        25% { transform: translateX(0.5rem); }
        75% { transform: translateX(-0.5rem); }
        100% { transform: translateX(0rem); }
    }

    &-content {
        position: absolute;
        top: 20%;
        left: 50%;
        transform: translateX(-50%);
        
        h1 {
            font-size: 3rem;
            text-align: center;
            margin-bottom: 2rem;
        }
        
        h2 {
            font-size: 2.5rem;
            text-align: center;
            margin-bottom: 3rem;
        }
        
        input {
            display: block;
            text-align: center;
            height: 5rem;
            width: 30rem;
            font-size: 2.5rem;
            border: none;
            outline: none;
            border-radius: 1rem;
            margin: 0 auto;
            margin-bottom: 2rem;

            &.error {
                animation: shake 0.2s ease-in-out 0s 2;
            }
        }
        
        a {
            display: block;
            background-color: orange;
            height: 5rem;
            width: 5rem;
            margin: 0 auto;
            border-radius: 1rem;
            font-size: 2rem;
            margin-bottom: 2rem;
            @include transition-ease(0.3s);

            p {
                display: none;
            }

            i {
                font-size: 3rem;
                margin: 1rem;
                color: #eee;
            }

            &.active {
                position: relative;
                width: 15rem;
                
                p {
                    position: absolute;
                    display: unset;
                    top: 1rem;
                    // margin: 1rem;
                    color: #eee;
                }
            }
        }

        input:focus::-webkit-input-placeholder  {color:transparent;}
        input:focus::-moz-placeholder   {color:transparent;}
    }
}