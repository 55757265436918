.cvpage {
    .nav {
        display: none;
    }

    .content {
        margin-top: 2rem;
        padding: 1rem;

        .info {
            overflow: hidden;
        }

        .introduction {
            text-align: center;
            border-bottom: 1px solid $secondary-color-opaque;

            p {
                padding: 1rem;
            }
        }

        h1 {
            color: $secondary-color;
            font-size: 1.5rem;
        }

        .education {
            margin-top: 1rem;
            text-align: right;
            border-bottom: 1px solid $secondary-color-opaque;

            h1 {
                margin-right: 2rem;
            }
            
            div {
                padding: 1rem 0;

                p {
                    width: 75%;
                    display: inline-block;
                    text-align: left;
                    padding-left: 1rem;
                }
                .years {
                    width: 25%;
                    float: left;
                }
            }
        }

        .exp {
            margin-top: 1rem;
            text-align: left;
            border-bottom: 1px solid $secondary-color-opaque;

            h1 {
                margin-left: 2rem;
            }

            div {
                padding: .5rem 1rem;

                span {
                    width: 40%;
                    float: left;
                }

                .company {
                    display: inline-block;
                    font-size: 1.2rem;
                    font-weight: 600;
                    color: $secondary-color;
                }

                .desc {
                    padding: .5rem;
                }
            }
        }

        .skills {
            margin-top: 1rem;
            text-align: center;
            border-bottom: 1px solid $secondary-color-opaque;

            h2 {
                margin-bottom: .5rem;
                font-size: 1.2rem;
                font-weight: 600;
                color: $secondary-color;
            }

            div {
                padding: .4rem;
            }

            .left {
                float: left;
                width: 50%;
                margin-bottom: 1rem;
                
                .language {
                    font-weight: 600;
                }
            }

            .right {
                float: right;
                width: 50%;
                margin-bottom: 1rem;
            }
        }

        .interests {
            margin-top: 1rem;
            border-bottom: 1px solid $secondary-color-opaque;
            text-align: right;
            
            h1 {
                margin-right: 2rem;
            }

            div {
                text-align: left;
                margin-bottom: 1rem;
                
                h2 {
                    margin-left: 1rem;
                    margin-top: .5rem;
                    font-size: 1.2rem;
                    font-weight: 600;
                    color: $secondary-color;
                }
                
                p {
                    margin-left: 1.5rem;
                }
            }
        }

        .pnc {
            margin-top: 1rem;
            border-bottom: 1px solid $secondary-color-opaque;

            h1 {
                margin-left: 2rem;
                margin-bottom: .5rem;
            }
            
            h2 {
                text-align: center;
                font-size: 2rem;
                font-weight: 700;
                color: $secondary-color;
                margin: -.5rem;
            }

            p {
                margin-top: .5rem;
            }
            
            div {
                text-align: center;
                padding: 0 1rem;
            }
            
            .pros {
                float: left;
                width: 50%;
                margin-bottom: 1rem;
            }
            
            .cons {
                float: right;
                width: 50%;
                margin-bottom: 1rem;
            }
        }

        .about {
            margin-top: 1rem;
            text-align: center;
            border-bottom: 1px solid $secondary-color-opaque;

            p {
                padding: 1rem;
            }
        }

        .contact {
            margin-top: 1rem;
            text-align: center;

            h2 {
                margin-top: 1rem;
                font-size: 1.2rem;
                font-weight: 600;
                color: $secondary-color;
            }
        }

        i {
            display: none;
        }
    }
}