@include media-md {
    body {
        .cvpage {
            padding: calc(min(13vh, 8rem)) 8% 1rem 8%;

            .nav {
                display: unset;
                span {
                    font-size: 1.25rem;
                    margin: 0 1rem;
                    cursor: pointer;
                }

                .bold {
                    font-weight: 600;
                }
                
                i {
                    font-size: 1.25rem;
                    color: #999;
                    transform: translateY(1px);
                }

                .orange {
                    color: $secondary-color;
                }
            }

            .content {
                margin-top: 0;
                // position: relative;

                i {
                    display: unset;
                    position: absolute;
                    top: calc(max(20rem, 50%));
                    // right: 20%;
                    transform: translateY(-75%);
                    font-size: 8rem;
                    color: $secondary-color-opaque;
                    cursor: pointer;
                    pointer-events: unset;
                    @include transition-ease(.3s);

                    &:hover {
                        color: $secondary-color;
                    }
                }

                .fa-angle-left {
                    left: calc(min(80rem, 80%));
                }

                .fa-angle-right {
                    left: calc(min(86rem, 88%));
                }

                .hidden {
                    display: none;
                }

                .info {
                    width: 40rem;
                    max-width: 70%;
                    border-bottom: none;
                    padding-left: 2rem;
                    
                    h1 {
                        margin: 3rem 0;
                        font-size: 3rem;
                        text-align: left;
                    }

                    .h1-mobile {
                        display: none;
                    }
                    
                    p {
                        text-align: left;
                        font-size: 1.3rem;
                    }

                    span {
                        font-size: 1.3rem;
                    }
                }
                
                .introduction {
                    border-bottom: none;
                    padding-top: 2rem;
                    
                    p {
                        margin-top: 5rem;
                        padding: 0;
                    }
                }

                .skills {
                    border-bottom: none;
                    padding-top: 5rem;
                    margin-top: 0;

                    h1 {
                        margin-bottom: 2rem;
                    }
                    
                    h2 {
                        font-size: 1.5rem;
                        font-weight: 600;
                    }

                    p {
                        padding: 0;
                        text-align: center;
                    }
                }

                .education {
                    border-bottom: none;
                    margin-top: 4rem;
                    text-align: left;
                    
                    div {
                        padding: 1rem;
                        p {
                            width: unset;
                            display: block;
                            padding: .25rem .5rem;
                        }
                        
                        .years {
                            width: unset;
                            margin: .5rem 0.25rem;
                            font-size: 1.5rem;
                            font-weight: 600;
                            color: $secondary-color;
                            padding: 0;
                            float: unset;
                        }
                    }
                }

                .exp {
                    padding-top: 4rem;
                    div {
                        .company {
                            font-size: 1.5rem;
                        }

                        .date {
                            padding-left: .25rem;
                        }

                        .desc {
                            padding-top: .25rem;
                        }
                    }
                }

                .pnc {
                    padding-top: 5.5rem;
                    margin-top: 0;

                    h2 {
                        font-size: 1.5rem;
                        font-weight: 600;
                        text-align: left;
                        margin: 0;
                    }
                    
                    p {
                        margin: .75rem .25rem;
                    }

                    .pros {
                        width: 30rem;
                        float: unset;
                    }
                    
                    .cons {
                        width: 30rem;
                        float: unset;
                        padding-top: 1rem;
                    }
                }

                .interests {
                    padding-top: 4rem;
                    div {
                        h2 {
                            font-size: 1.5rem;
                            margin-bottom: .5rem;
                        }

                        p {
                            margin-bottom: 1rem;
                        }
                    }
                }

                .about {
                    border-bottom: none;
                    padding-top: 2rem;
                    margin-top: 0;
                    
                    p {
                        margin-top: 5rem;
                        padding: 0;
                    }
                }

                .contact {
                    padding-top: 4rem;
                    div {
                        width: 30rem;
                        
                        h2 {
                            font-size: 1.75rem;
                        }

                        p {
                            text-align: center;
                            font-size: 1.5rem;
                        }
                    }

                }
            }
        }
    }
}