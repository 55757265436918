*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

@import url("//fonts.googleapis.com/css?family=Open+Sans:300,400,600,700");

body {
    box-sizing: border-box;
    background: $background-color;
    font-family: 'Open Sans';
}

svg {
    pointer-events: none;
}

i {
    pointer-events: none;
}

a {
    user-select: none;
}

a:hover {
    cursor: pointer;
}


h1, h2, h3 {
    cursor: default;
    font-size: 1rem;
}